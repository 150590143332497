import Swal from "sweetalert2";
import ApiService from "@/core/services/ApiService";

type LoginError = {
  message: string;
  type: string;
  userID?: number;
};

async function resendActivationMail(userID: number | undefined) {
  try {
    const response = await ApiService.get(
      `api/v2/resendActivationMail/${userID}`
    );
    return response;
  } catch (error) {
    throw new Error("Failed to resend activation mail");
  }
}

async function confirmEmail(error: LoginError) {
  try {
    await Swal.fire({
      title: error.message,
      icon: "info",
      heightAuto: false,
      showCancelButton: true,
      confirmButtonText: "Resend email",
      showLoaderOnConfirm: true,
      preConfirm: () => resendActivationMail(error.userID),
      allowOutsideClick: () => !Swal.isLoading()
    });

    await Swal.fire({
      title: "Email resent, please check your inbox.",
      heightAuto: false,
      icon: "success"
    });
  } catch {
    Swal.showValidationMessage(`Sorry, there was an error.`);
  }
}

function showSimpleAlert(
  title: string,
  icon: "error" | "success",
  duration = 1000
) {
  Swal.fire({
    position: "top-end",
    icon,
    title,
    toast: true,
    timer: duration,
    showConfirmButton: false,
    showCancelButton: false
  });
}

function showModalAlert(title: string, icon: "error" | "success") {
  Swal.fire({
    title,
    icon,
    heightAuto: false
  });
}

async function handleLoginError(error: LoginError) {
  switch (error.type) {
    case "confirm_email":
      await confirmEmail(error);
      break;

    case "invalid_credentials":
      showSimpleAlert(error.message, "error");
      break;

    case "invalid_otp":
      showModalAlert(error.message, "error");
      break;

    default:
      showSimpleAlert("Sorry, there was an error.", "error");
  }
}

export { handleLoginError };
