
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { AbilityBuilder, Ability } from "@casl/ability";
import { useAbility } from "@casl/vue";
import OtpInput from "@/views/otp/OtpInput.vue";
import { handleLoginError } from "@/core/helpers/sign_in_page_helpers";
import AccessService from "@/core/services/AccessService";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    OtpInput,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const ability = useAbility();
    const loading = ref<boolean>(false);
    const { can, rules } = new AbilityBuilder(Ability);
    const requireOtp = ref<boolean>(false);
    const email = ref<string>("");
    const password = ref<string>("");
    const otpKey = ref<number>(0);

    const submitButton = ref<HTMLButtonElement | null>(null);

    function setLoading(value: boolean): void {
      loading.value = value;
    }

    const login = Yup.object().shape({
      email: Yup.string()
        .email()
        .required()
        .label("Email"),
      password: Yup.string()
        .min(4)
        .required()
        .label("Password")
    });

    onMounted(() => {
      const route = useRoute();
      if (route.query.wasVerified != undefined) {
        router.replace({ query: {} });
        Swal.fire({
          icon: "success",
          title: "Email succesfully verified, you can now login!",
          timer: 2000,
          heightAuto: false
        });
      }
    });
    const onSubmitLogin = values => {
      store.dispatch(Actions.LOGOUT);
      setLoading(true);
      store
        .dispatch(Actions.LOGIN, values)
        .then(payload => {
          payload.rules.forEach(rule => {
            can(rule, "all");
          });
          setLoading(false);
          ability.update(rules);
          if (payload.should_enable_2fa) {
            router.push("/otp/enable");
            return;
          } else if (payload.role[0].title == "Applicant") {
            router.push("/users/me/personal");
            return;
          }
          router.push("/");
        })
        .catch(responce => {
          setLoading(false);
          switch (responce.type) {
            case "otp_required":
              requireOtp.value = true;
              email.value = values.email;
              password.value = values.password;
              break;
            case "2fa_mandatory":
              handleLoginError(responce);
              break;
            case "invalid_credentials":
              Swal.fire({
                title: "Invalid credentials",
                icon: "error",
                showCancelButton: false
              });
              break;
          }
        });
    };

    const handleOtpSubmit = payload => {
      setLoading(true);
      store
        .dispatch(Actions.LOGIN, {
          email: email.value,
          password: password.value,
          otp: payload.code,
          rememberDevice: payload.rememberDevice
            ? payload.rememberDevice
            : false
        })
        .then(payload => {
          payload.rules.forEach(rule => {
            can(rule, "all");
          });

          ability.update(rules);
          if (payload.role[0].title == "Applicant")
            router.push("/users/me/personal");
          else router.push("/");
          setLoading(false);
        })
        .catch(responce => {
          if (responce.type === "invalid_otp") {
            otpKey.value += 1;
          }
          setLoading(false);
          handleLoginError(responce);
        });
    };

    return {
      onSubmitLogin,
      handleOtpSubmit,
      loading,
      otpKey,
      requireOtp,
      login,
      submitButton
    };
  }
});
