<template>
  <form
    class="form w-100 mb-5"
    @submit.prevent="ev => handleFormSubmit(ev)"
    novalidate="novalidate"
    v-loading="loading"
    data-kt-redirect-url="index.html"
    id="kt_sing_in_two_factor_form"
  >
    <div class="text-center mb-10">
      <img alt="Logo" class="mh-125px" src="/media/svg/misc/smartphone.svg" />
    </div>
    <div class="text-center mb-10">
      <h1 class="text-gray-900 mb-3">Type Your Code</h1>
      <div class="text-muted fw-semibold fs-5 mb-5">
        Type your 6 digit security code from the Google Authenticator app on
        your phone
      </div>
    </div>
    <div>
      <div class="fw-bold text-start text-gray-900 fs-6 mb-1 ms-1">
        Your 6 digit security code
      </div>
      <OtpCodeInput @code-ready="handleFormSubmit" />
      <div class="d-flex justify-content-start mt-7 ms-1">
        <label class="d-flex align-items-center user-select-none">
          <span class="form-check form-check-custom form-check-solid"
            ><input
              class="form-check-input"
              type="checkbox"
              @change="handleRememberMe"
              id="remember_device_check"
          /></span>
          <span class="text-dark fs-6 fw-bold ps-3 cursor-pointer">
            Remember me
          </span>
        </label>
      </div>
    </div>
  </form>
</template>

<script>
import { defineComponent } from "vue";
import OtpCodeInput from "@/components/otp/OtpCodeInput.vue";

export default defineComponent({
  name: "otp-input",
  props: {
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  components: { OtpCodeInput },
  data: () => ({
    rememberDevice: false
  }),
  methods: {
    handleRememberMe(event) {
      this.rememberDevice = event.target.checked;
    },
    handleFormSubmit(code) {
      this.$emit("submitOtp", {
        code: code,
        rememberDevice: this.rememberDevice
      });
    }
  }
});
</script>

<style scoped></style>
